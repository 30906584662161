import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { MaintenanceStateColor } from '@hkm/shared/enum/badgeColor';
import classNames from 'classnames';

import { RoomMaintenanceState } from '@ac/library-api';
import {
  Childless,
  Styleable,
} from '@ac/mobile-components/dist/interfaces/componentProps';
import { BadgeSize, ColorsPaletteKey } from '@ac/web-components';

interface MaintenanceStateBadgeProps extends Styleable, Childless {
  status: RoomMaintenanceState;
  showLabel?: boolean;
}

const colorMap = new Map<RoomMaintenanceState, MaintenanceStateColor>()
  .set(RoomMaintenanceState.Active, MaintenanceStateColor.Active)
  .set(RoomMaintenanceState.Pending, MaintenanceStateColor.Pending)
  .set(RoomMaintenanceState.Released, MaintenanceStateColor.Released)
  .set(RoomMaintenanceState.Canceled, MaintenanceStateColor.Canceled);

const MaintenanceStateBadge: FC<MaintenanceStateBadgeProps> = (
  props: MaintenanceStateBadgeProps
) => {
  const { t } = useTranslation();
  const color = colorMap.get(props.status) || ColorsPaletteKey.fog300;
  const badgeText = t(`GLOBAL.MAINTENANCE_STATE.${props.status}`);
  const labelText = props.showLabel ? badgeText : undefined;
  const testSelectorPrefix = 'maintenance-details';

  const className: string = classNames(
    'maintenance-state-badge',
    props.className
  );

  return (
    <ac-badge
      class={className}
      content={badgeText}
      text={labelText}
      color={color}
      size={BadgeSize.lg}
      data-test-selector={`${testSelectorPrefix}-state`}
    />
  );
};

export default MaintenanceStateBadge;
