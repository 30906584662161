import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getRoomConditions } from '@hkm/components/App/domain/selectors';
import classNames from 'classnames';

import { IdCodeDescriptionColorDto } from '@ac/library-api';
import { AlignItems } from '@ac/mobile-components/dist/enums';
import {
  Childless,
  Styleable,
  Testable,
} from '@ac/mobile-components/dist/interfaces/componentProps';
import { getTestSelectorProp } from '@ac/mobile-components/dist/utils';
import { BadgeSize, ColorsPaletteKey } from '@ac/web-components';

import './RoomConditionsBadge.css';

interface RoomConditionsBadgeProps extends Styleable, Childless, Testable {
  roomConditions?: IdCodeDescriptionColorDto[];
}

export const RoomConditionsBadge = ({
  roomConditions,
  testSelector,
  className,
}: RoomConditionsBadgeProps): JSX.Element | null => {
  const { t } = useTranslation();
  const classNameProps = classNames('room-conditions-badge', className);
  const roomConditionDictionary = useSelector(getRoomConditions);
  const inactiveRoomConditions = useMemo(() => {
    return roomConditionDictionary
      ?.filter((item) => !item.isActive)
      .map((room) => room.code);
  }, [roomConditionDictionary]);

  const badgeText = useCallback(
    (condition: IdCodeDescriptionColorDto): string | undefined => {
      return inactiveRoomConditions?.find((code) => code === condition.code)
        ? t('GLOBAL.ROOM_CONDITIONS.INACTIVE', { code: condition.code })
        : condition.code;
    },
    [t, inactiveRoomConditions]
  );

  if (!roomConditions) {
    return null;
  }

  return (
    <ac-flex wrapped alignItems={AlignItems.center} class={classNameProps}>
      {roomConditions.map((condition) => (
        <ac-badge
          key={condition.id}
          color={condition.color ?? ColorsPaletteKey.blue50}
          class="room-conditions-badge-element"
          size={BadgeSize.lg}
          {...getTestSelectorProp(testSelector, 'room-conditions')}
          content={badgeText(condition)}
        />
      ))}
    </ac-flex>
  );
};
