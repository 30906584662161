import {
  FeatureRequirements,
  FeatureToggleRequirement,
  PermissionRequirement,
  PropertyContext,
  SettingRequirement,
} from '@ac/library-api';

export interface AttendantAssignmentFeatureActions {
  summary: FeatureRequirements<void>;
  summaryV2: FeatureRequirements<void>;
}

export const getAttendantAssignmentFeature = (
  businessContext: PropertyContext | undefined
): AttendantAssignmentFeatureActions => {
  const permissions = businessContext?.permissions.processed;
  const settings = businessContext?.settings.processed;
  const toggles = businessContext?.featureToggles;

  return {
    summary: new FeatureRequirements<void>([
      new PermissionRequirement({
        condition:
          permissions?.housekeeping.attendantAssignment
            .hasPermissionToUseMobileApp ?? false,
      }),
      new SettingRequirement({
        condition:
          settings?.housekeeping.isAttendantMobileApplicationEnabled ?? false,
      }),
      new FeatureToggleRequirement({
        condition: !toggles?.housekeeping.isHousekeepingShiftsEnabled,
      }),
    ]),
    summaryV2: new FeatureRequirements<void>([
      new PermissionRequirement({
        condition:
          permissions?.housekeeping.attendantAssignment
            .hasPermissionToUseMobileApp ?? false,
      }),
      new SettingRequirement({
        condition:
          settings?.housekeeping.isAttendantMobileApplicationEnabled ?? false,
      }),
      new FeatureToggleRequirement({
        condition: toggles?.housekeeping.isHousekeepingShiftsEnabled ?? false,
      }),
    ]),
  };
};
