import React, { useMemo } from 'react';
import { AttributeDictionary } from '@hkm/components/TaskManagement/relations/domain/interfaces';

import { BadgeSize, BadgeTheme, ColorsPaletteKey } from '@ac/web-components';

interface ComponentProps {
  attributesCodes: string[];
  attributes: AttributeDictionary[];
}

export const Preferences = (props: ComponentProps): JSX.Element => {
  const preferences = useMemo(
    () =>
      (props.attributesCodes || []).map((attributeCode) => {
        const preferenceAttribute = (props.attributes || []).find(
          ({ code }) => code === attributeCode
        );

        const attributeColor = preferenceAttribute?.ref_attributeGroupId?.color;

        return {
          id: preferenceAttribute?.id,
          code: preferenceAttribute?.code,
          attributeColor,
        };
      }),
    [props.attributesCodes, props.attributes]
  );

  return (
    <ac-flex wrapped={true}>
      {preferences.map((preference, index) =>
        preference.attributeColor ? (
          <ac-badge
            class="ac-spacing-right-sm ac-spacing-bottom-sm"
            key={preference.id}
            content={preference.code}
            size={BadgeSize.lg}
            color={preference.attributeColor}
            data-test-selector={`task-preference-${index}`}
          />
        ) : (
          <ac-badge
            class="ac-spacing-right-sm ac-spacing-bottom-sm"
            key={preference.id}
            content={preference.code}
            color={ColorsPaletteKey.blue400}
            theme={BadgeTheme.informationalTheme}
            size={BadgeSize.lg}
            data-test-selector={`task-preference-${index}`}
          />
        )
      )}
    </ac-flex>
  );
};
