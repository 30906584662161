import React, { FC, memo, useCallback, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, Link } from 'react-router-dom';
import { getDashboardSearchString } from '@hkm/components/TaskManagement/Dashboard/domain/selectors';
import { TaskDashboardItemWithFlag } from '@hkm/components/TaskManagement/domain/interfaces/taskDashboardItemWithFlag';
import TaskOverview from '@hkm/components/TaskManagement/shared/components/TaskOverview/TaskOverview';
import { getDetails } from '@hkm/components/TaskManagement/utils/taskDetails';
import APP_ROUTES from '@hkm/constants/routing.constants';

import { AcTileShell } from '@ac/mobile-components/dist/components/tile';
import { BadgeSize, ColorsPaletteKey } from '@ac/web-components';

import './DashboardItem.css';

interface ComponentProps {
  task: TaskDashboardItemWithFlag;
  index: number;
}

const DashboardItem: FC<ComponentProps> = (props) => {
  const { task, index } = props;
  const dataTestSelector = `task-management-dashboard-item-${index}`;
  const searchValue = useSelector(getDashboardSearchString);
  const actionsButtonRef = useRef<HTMLDivElement>(null);

  const taskAdditionalInfo = useMemo(() => {
    const searchDetails = getDetails(searchValue, task.objectInfo);

    return searchDetails;
  }, [searchValue, task.objectInfo]);

  const onTaskTap = useCallback((e: React.MouseEvent) => {
    if (actionsButtonRef.current?.contains(e.target as Node)) {
      e.preventDefault();
    }
  }, []);

  return (
    <AcTileShell
      className="task-dashboard-item"
      testSelector={dataTestSelector}
    >
      <Link
        className="unstyled-link"
        to={generatePath(APP_ROUTES.TASK_MANAGEMENT.TASK_PREVIEW, {
          taskId: task.id,
        })}
        onClick={onTaskTap}
      >
        <TaskOverview
          task={props.task}
          index={props.index}
          dashboardView={true}
          actionsButtonRef={actionsButtonRef}
        />
        {searchValue && taskAdditionalInfo && (
          <ac-flex class="ac-spacing-top-sm">
            <ac-badge
              content={taskAdditionalInfo}
              color={ColorsPaletteKey.fog300}
              size={BadgeSize.lg}
              data-test-selector={`task-dashboard-item-additional-info-${index}`}
            />
          </ac-flex>
        )}
      </Link>
    </AcTileShell>
  );
};

export default memo(DashboardItem);
