import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { MaintenanceBadgeColor } from '@hkm/shared/enum/badgeColor';
import classNames from 'classnames';

import { RoomMaintenanceStatus } from '@ac/library-api';
import { TextSize } from '@ac/mobile-components/dist/enums';
import {
  Childless,
  Styleable,
  Testable,
} from '@ac/mobile-components/dist/interfaces/componentProps';
import { getTestSelectorProp } from '@ac/mobile-components/dist/utils';
import { BadgeSize, ColorsPaletteKey } from '@ac/web-components';

interface MaintenanceStatusBadgeProps extends Styleable, Childless, Testable {
  status: RoomMaintenanceStatus;
  showLabel?: boolean;
  fullTextInTile?: boolean;
  badgeSize?: BadgeSize;
  badgeTextSize?: TextSize;
}

const colorMap = new Map<RoomMaintenanceStatus, MaintenanceBadgeColor>()
  .set(RoomMaintenanceStatus.OutOfOrder, MaintenanceBadgeColor.OOO)
  .set(RoomMaintenanceStatus.OutOfService, MaintenanceBadgeColor.OOS);

const MaintenanceStatusBadge: FC<MaintenanceStatusBadgeProps> = (props) => {
  const { t } = useTranslation();
  const color = colorMap.get(props.status) || ColorsPaletteKey.fog500;
  const labelText = props.showLabel
    ? t(`GLOBAL.MAINTENANCE_STATUS.LONG.${props.status}`)
    : undefined;
  const badgeText = t(
    `GLOBAL.MAINTENANCE_STATUS.${props.fullTextInTile ? 'LONG' : 'SHORT'}.${
      props.status
    }`
  );

  const className = classNames('maintenance-status-badge', props.className);

  return (
    <ac-badge
      {...getTestSelectorProp(props.testSelector, 'maintenanceStatusBadge')}
      class={className}
      content={badgeText}
      text={labelText}
      color={color}
      size={props.badgeSize ?? BadgeSize.lg}
    />
  );
};

export default MaintenanceStatusBadge;
