import React, { FC, memo, PropsWithChildren, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  selectEffectiveValues,
  selectPropertyDateFormats,
} from '@hkm/components/Menu/PropertySelector/domain/selectors';
import {
  taskProgressColorMap,
  taskProgressTextColorMap,
} from '@hkm/components/shared/AttendantTaskProgressTypeBadge/AttendantTaskProgressTypeBadge';
import {
  getAllVipAndMemberBadges,
  hasReservationsDisabilities,
} from '@hkm/components/shared/Templates/Reservation/utils/reservationUtils';
import { AttendantRoom } from '@hkm/types/attendant/models/AttendantRoom';
import { createTimeWithTimezone } from '@hkm/utils/dateHelper';
import { uniqBy } from 'lodash';

import { AttendantTaskProgressType } from '@ac/library-api';
import { AcIndicator } from '@ac/mobile-components/dist/components/indicator';
import {
  AcModal,
  AcModalBody,
} from '@ac/mobile-components/dist/components/modal';
import {
  FlexDirection,
  JustifyContent,
  MobileColor,
} from '@ac/mobile-components/dist/enums';
import { ChildRequired } from '@ac/mobile-components/dist/interfaces/componentProps';
import {
  AlignItems,
  Color,
  ColorsPaletteKey,
  CounterType,
  FlexGap,
  IconName,
  Size,
  TextColor,
  TextSize,
  TextWeight,
} from '@ac/web-components';

import './AttendantRoomItemHeader.css';

interface AttendantRoomItemHeaderProps extends ChildRequired {
  room: AttendantRoom;
  taskType: AttendantTaskProgressType;
}

/* tslint:disable:jsx-no-lambda */
const AttendantRoomItemHeader: FC<
  PropsWithChildren<AttendantRoomItemHeaderProps>
> = (props: PropsWithChildren<AttendantRoomItemHeaderProps>) => {
  const { t } = useTranslation();

  const prefix = 'attendant-room-item-header';
  const [isModalVisible, setModalVisibility] = useState<boolean>(false);

  const allReservation = props.room.currentReservations || [];
  const { isInPendingRoomQueue, serviceRequested } = props.room;
  const hasAnyDisabilities = hasReservationsDisabilities(allReservation);
  const allVipAndMemberships = uniqBy(
    getAllVipAndMemberBadges(allReservation),
    'color'
  );
  const hasAnyVipAndMemberships = allVipAndMemberships.length > 0;

  function openModal(): void {
    setModalVisibility(true);
  }

  function closeModal(): void {
    setModalVisibility(false);
  }

  const desiredTime =
    props.room.queueRoomEntries && props.room.queueRoomEntries[0]?.desiredTime
      ? props.room.queueRoomEntries[0]?.desiredTime
      : undefined;
  const formats = useSelector(selectPropertyDateFormats);
  const effectiveValues = useSelector(selectEffectiveValues);
  const formattedDesiredTime =
    desiredTime && createTimeWithTimezone(desiredTime).format(formats.time);

  const isDesiredTimeEnabled = effectiveValues?.enabledReadyBy;

  const headerText = useMemo(() => {
    if (isInPendingRoomQueue && serviceRequested) {
      return `${t('GLOBAL.QUEUE_ROOM')}, ${t(
        'GLOBAL.GUEST_SERVICE_STATUS.VALUES.serviceRequested'
      )}`;
    }

    if (isInPendingRoomQueue && !serviceRequested) {
      return t('GLOBAL.QUEUE_ROOM');
    }

    if (!isInPendingRoomQueue && serviceRequested) {
      return t('GLOBAL.GUEST_SERVICE_STATUS.VALUES.serviceRequested');
    }

    return undefined;
  }, [t, isInPendingRoomQueue, serviceRequested]);

  return (
    <>
      <ac-flex
        class={
          isInPendingRoomQueue || serviceRequested
            ? `${prefix} is-alert-bar`
            : ''
        }
        alignItems={AlignItems.center}
        justifyContent={JustifyContent.spaceBetween}
      >
        {!isInPendingRoomQueue && !serviceRequested ? (
          <div className={`${prefix}-room-number`}>
            <ac-flex alignItems={AlignItems.center} columnGap={FlexGap.sm}>
              <ac-counter
                type={CounterType.dot}
                color={
                  taskProgressColorMap.get(props.taskType) ??
                  ColorsPaletteKey.black
                }
              />
              <ac-text
                color={
                  taskProgressTextColorMap.get(props.taskType) ??
                  TextColor.black
                }
                weight={TextWeight.semibold}
                size={TextSize.lg}
              >
                {props.children}
              </ac-text>
            </ac-flex>
          </div>
        ) : (
          <ac-flex
            class={`${prefix}-room-number`}
            alignItems={AlignItems.center}
          >
            <ac-flex class={`${prefix}-room-number-wrapper`}>
              <ac-icon
                class="ac-spacing-right-sm ac-spacing-left-sm"
                icon={
                  isInPendingRoomQueue && !serviceRequested
                    ? IconName.queue
                    : IconName.todo
                }
                color={Color.warning}
                size={Size.md}
              />
              <ac-flex direction={FlexDirection.column}>
                <ac-text size={TextSize.lg}>{headerText}</ac-text>
                <ac-text weight={TextWeight.semibold} size={TextSize.h3}>
                  {props.children}
                </ac-text>
              </ac-flex>
            </ac-flex>
            {isDesiredTimeEnabled && desiredTime && (
              <ac-flex
                direction={FlexDirection.column}
                class="ready-by-time-room-dashboard"
              >
                <ac-text size={TextSize.lg}>
                  {t('ATTENDANT_ASSIGNMENTS.DESIRED_TIME')}
                </ac-text>
                <ac-text
                  color={TextColor.black}
                  weight={TextWeight.semibold}
                  size={TextSize.h3}
                >
                  {formattedDesiredTime}
                </ac-text>
              </ac-flex>
            )}
          </ac-flex>
        )}
        <ac-text
          color={TextColor.black}
          size={TextSize.h3}
          class={`align-right ${
            isInPendingRoomQueue || serviceRequested
              ? 'ac-spacing-right-sm'
              : ''
          }`}
        >
          {hasAnyDisabilities && (
            <AcIndicator
              onClick={openModal}
              icon={IconName.disability}
              backgroundColor={MobileColor.Alert}
              className="ac-spacing-left-sm"
            />
          )}
          {hasAnyVipAndMemberships &&
            allVipAndMemberships?.map((item) => (
              <AcIndicator
                key={item.color}
                onClick={openModal}
                icon={IconName.vip}
                backgroundColor={item.color}
                className="ac-spacing-left-sm"
              />
            ))}
        </ac-text>
      </ac-flex>

      <AcModal
        className="attendant-room-item-modal"
        isOpen={isModalVisible}
        onClose={closeModal}
      >
        <AcModalBody className="attendant-room-item-modal-body">
          {hasAnyDisabilities && (
            <ac-flex
              alignItems={AlignItems.center}
              class="ac-spacing-bottom-lg"
            >
              <AcIndicator
                className="ac-spacing-right-sm"
                icon={IconName.disability}
                backgroundColor={MobileColor.Alert}
              />
              <ac-text>
                {t('ATTENDANT_ASSIGNMENTS.ROOM_LIST.GUEST_WITH_SPECIAL_NEEDS')}
              </ac-text>
            </ac-flex>
          )}
          {hasAnyVipAndMemberships &&
            allVipAndMemberships?.map((item, key) => (
              <ac-flex
                key={item.color}
                alignItems={AlignItems.center}
                class={
                  key === allVipAndMemberships?.length - 1
                    ? ''
                    : 'ac-spacing-bottom-lg'
                }
              >
                <AcIndicator
                  className="ac-spacing-right-sm"
                  backgroundColor={item.color}
                  icon={IconName.vip}
                />
                <ac-text>{item?.description}</ac-text>
              </ac-flex>
            ))}
        </AcModalBody>
      </AcModal>
    </>
  );
};

export default memo(AttendantRoomItemHeader);
