import { ColorsPaletteKey } from '@ac/web-components';

export const BadgeColor = {
  Default: ColorsPaletteKey.cyan300,
  Alert: ColorsPaletteKey.red600,
  Warning: ColorsPaletteKey.orange400,
  Black: ColorsPaletteKey.black,
  White: ColorsPaletteKey.white,
};

export enum RoomBadgeColor {
  Inspected = '5CB85C',
  Dirty = 'D9534F',
  Clean = '5BC0DE',
  Pickup = 'F0AD4E',
  Default = '000000',
}

export enum MaintenanceBadgeColor {
  OOO = '000000',
  OOS = '742109',
}

export enum MaintenanceStateColor {
  Active = 'eaa630',
  Pending = '80e8ff',
  Released = '5cb85c',
  Canceled = 'd9534f',
  Default = '000000',
}

export enum RoomQueueBadgeColor {
  Pending = '620173',
  Ready = '109128',
}

export enum DiscrepancyBadgeColor {
  Sleep = 'EAA630',
  Skip = 'D9534F',
  Occupancy = 'AA9732',
}
