import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { getRoomStatesMap } from '@hkm/components/App/domain/selectors';
import { RoomBadgeColor } from '@hkm/shared/enum/badgeColor';
import classNames from 'classnames';

import { RoomStatus } from '@ac/library-api';
import {
  Childless,
  Styleable,
  Testable,
} from '@ac/mobile-components/dist/interfaces/componentProps';
import { formatTestSelector } from '@ac/mobile-components/dist/utils';
import { BadgeSize, ColorsPaletteKey } from '@ac/web-components';

interface RoomStatusBadgeProps extends Styleable, Childless, Testable {
  status: RoomStatus;
  showLabel?: boolean;
}

const colorMap = new Map<RoomStatus, RoomBadgeColor>()
  .set(RoomStatus.CL, RoomBadgeColor.Clean)
  .set(RoomStatus.DI, RoomBadgeColor.Dirty)
  .set(RoomStatus.IN, RoomBadgeColor.Inspected)
  .set(RoomStatus.PU, RoomBadgeColor.Pickup);

const RoomStatusBadge: FC<RoomStatusBadgeProps> = (
  props: RoomStatusBadgeProps
) => {
  const color = colorMap.get(props.status) || ColorsPaletteKey.fog500;
  const status = useSelector(getRoomStatesMap).get(props.status);

  const labelText = props.showLabel ? status?.name : undefined;
  const badgeText = status?.code;

  const className: string = classNames('room-status-badge', props.className);

  return (
    <ac-badge
      data-test-selector={formatTestSelector(
        props.testSelector,
        'roomStatusBadge'
      )}
      class={className}
      content={badgeText}
      size={BadgeSize.lg}
      text={labelText}
      color={color}
    />
  );
};

export default memo(RoomStatusBadge);
